import React, { useState, useEffect } from 'react';
import nprogress from 'nprogress';
import { AdminClient, AdminGroup } from 'services';
import { useAuth } from 'context/auth';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';

import {
  chakra,
  useToast,
  Button,
  Badge,
  Icon,
  IconButton,
  Text,
  HStack,
  SimpleGrid,
  VStack,
  Heading,
  Box,
  Stack,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
} from '@chakra-ui/react';
import { Select } from 'components/Forms/Select';

import Breadcrumbs from 'components/Breadcrumbs';
import { Content, Paper } from 'components/Content';
import ButtonSearch from 'components/ButtonSearch';
import Pagination from 'components/Pagination';
import Result from 'components/Result';
import ShowingItemsLegend from 'components/Tables/ShowingItemsLegend';
import ItemsPerPage from 'components/Tables/ItemsPerPage';
import PieChart from 'components/Charts';

import { useDelayedValue } from 'hooks/useDelayedValue';
import { useCan } from 'hooks/useCan';
import { date, daysDistance } from 'utils/format';
import { RiEdit2Fill, RiDeleteBin2Fill, RiMore2Fill } from 'react-icons/ri';
import { BsInboxFill } from 'react-icons/bs';
import { AiOutlineFileSearch } from 'react-icons/ai';

import Create from './create';
import Update from './update';
import Delete from './delete';

export default () => {
  let { pathname } = useLocation();
  let { type } = useParams();
  const [searchParams] = useSearchParams();
  const { auth, signInManager } = useAuth();
  const toast = useToast();
  const affinityCan = useCan({ access_role: ['AFFINITY'] });
  const econformCan = useCan({ access_role: ['ECONFORM'] });
  const editorCan = useCan({ access_role: ['EDITOR'] });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [group, setGroup] = useState('');
  const [limit, setLimit] = useState(20);
  const [createItem, setCreateItem] = useState(false);
  const [updateItem, setUpdateItem] = useState(0);
  const [deleteItem, setDeleteItem] = useState(0);

  const searchDelayed = useDelayedValue(search, 1000);

  const getClients = async (page = 0) => {
    try {
      nprogress.start();
      const { data } = await AdminClient.index(
        `group=${type === 'business' ? '1' : group?.value || ''}&filter=${type === 'providers' ? '"PROVIDER"' : `"CLIENT"`}&search=${searchDelayed}&page=${page}&limit=${limit}`
      );
      nprogress.done();
      console.log('CLIENTES: ', data);
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar os clientes.',
        description: error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const getGroups = async (page = 0) => {
    try {
      nprogress.start();
      const { data } = await AdminGroup.index(``);
      nprogress.done();
      console.log('GROUPS: ', data);
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar os dados.',
        description: error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const getKpi = async (groupId = 0) => {
    try {
      nprogress.start();
      const { data } = await AdminClient.getKpiGroup(groupId);
      nprogress.done();
      console.log('KPI: ', data);
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar os dados.',
        description: error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const clientsQuery = useQuery({
    queryKey: ['@eConform-getClients', type, page, searchDelayed, limit, group],
    queryFn: () => getClients(page),
    keepPreviousData: true,
  });

  const groupsQuery = useQuery({
    queryKey: ['@eConform-getGroups'],
    queryFn: () => getGroups(page),
    keepPreviousData: true,
  });

  const kpiQuery = useQuery({
    queryKey: ['@eConform-getKpi', group],
    queryFn: () => getKpi(group?.value || ''),
    keepPreviousData: true,
  });

  const optionsFilterGroups = groupsQuery?.data
    ?.filter((item) => item?.id != '1')
    .map((group) => ({
      value: group?.id,
      label: group?.name,
    }));

  const handleFilterGroup = (e) => {
    setGroup(e);
  };

  const handleSignInManager = (id) => {
    signInManager(id);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      setPage(0);
      setSearch(e.target.value);
    } else {
      setPage(0);
      setSearch(e.target.value);
    }
  };

  const handleClearSearch = () => {
    setPage(0);
    setSearch('');
  };

  const handlePerRowsChange = (e) => {
    setLimit(e.target.value);
  };

  console.log('KPI ', kpiQuery?.data);

  var dataLicenses = kpiQuery?.data && [
    {
      type: 'Em conformidade',
      value: kpiQuery?.data[0]?.license_in_conformity || 0,
      color: '#2ed47a',
    },
    {
      type: 'Pendentes',
      value:
        kpiQuery?.data[0]?.license_pending ||
        kpiQuery?.data[0]?.total_license -
        (kpiQuery?.data[0]?.license_in_conformity +
          kpiQuery?.data[0]?.license_expired +
          kpiQuery?.data[0]?.license_near_expiration +
          kpiQuery?.data[0]?.license_renewing +
          kpiQuery?.data[0]?.license_not_necessary),
      color: '#ffb946',
    },
    {
      type: 'Vencidas',
      value: kpiQuery?.data[0]?.license_expired || 0,
      color: '#f7685b',
    },
    {
      type: 'Expirando',
      value: kpiQuery?.data[0]?.license_near_expiration || 0,
      color: '#885AF8',
    },
    {
      type: 'Renovando',
      value: kpiQuery?.data[0]?.license_renewing || 0,
      color: '#109CF1',
    },
    {
      type: 'Não necessárias',
      value: kpiQuery?.data[0]?.license_not_necessary || 0,
      color: '#666',
    },
    /* {
      type: 'Não encontradas',
      value: 0,
      color: '#000',
    }, */
  ];

  var dataSituations = kpiQuery?.data && [
    {
      type: 'Ativas',
      value: kpiQuery?.data[0]?.companies_active || 0,
      color: '#2ed47a',
    },
    {
      type: 'Suspensas',
      value: kpiQuery?.data[0]?.companies_suspended || 0,
      color: '#ffb946',
    },
    {
      type: 'Baixadas',
      value: kpiQuery?.data[0]?.companies_closed || 0,
      color: '#f7685b',
    },
  ];

  var dataCNPJs = kpiQuery?.data && [
    {
      type: 'Baixo Risco',
      value: kpiQuery?.data[0]?.cnpjs_low || 0,
      color: '#2ed47a',
    },
    {
      type: 'Médio Risco',
      value: kpiQuery?.data[0]?.cnpjs_medium || 0,
      color: '#ffb946',
    },
    {
      type: 'Alto Risco',
      value: kpiQuery?.data[0]?.cnpjs_high || 0,
      color: '#f7685b',
    },
  ];

  return (
    <>
      <Breadcrumbs
        title={
          type === 'client'
            ? 'Clientes'
            : type === 'business'
              ? 'Business'
              : type === 'affinitys'
                ? 'Affinitys'
                : type === 'provider' || type === 'providers'
                  ? 'Providers'
                  : 'Contas'
        }
        pages={[
          { page: 'Início', link: '/admin/accounts/business' },
          /* {
            page:
              type === 'client'
                ? 'Clientes'
                : type === 'business'
                ? 'Business'
                : type === 'affinitys'
                ? 'Affinitys'
                : type === 'provider' || type === 'providers'
                ? 'Providers'
                : 'Contas',
            link: `/admin/accounts/${type}`,
          }, */
        ]}
      />

      <Content>
        <Paper
          title={
            <HStack spacing="24px">
              <div>
                {type === 'client'
                  ? 'Clientes'
                  : type === 'business'
                    ? 'Clientes Business'
                    : type === 'affinitys'
                      ? 'Clientes de Affinitys'
                      : type === 'provider' || type === 'providers'
                        ? 'Clientes Providers'
                        : 'Contas'}
              </div>
              {(econformCan || affinityCan || editorCan) && (
                <Box minW={{ base: '100%', xl: '250px' }} textAlign="left">
                  {/* <chakra.span fontSize="9px">Filtrar por Affinity</chakra.span> */}
                  {type !== 'business' && (
                    <Select
                      size="md"
                      placeholder="Todos"
                      isClearable={false}
                      options={[{ label: 'TODOS', value: '' }, ...optionsFilterGroups]}
                      isMulti={false}
                      onChange={(e) => {
                        handleFilterGroup(e);
                      }}
                      value={group}
                    />
                  )}
                </Box>
              )}
            </HStack>
          }
          fluid
          loading={clientsQuery.isLoading}
          fetching={clientsQuery.isFetching || clientsQuery.isRefetching}
          options={<Button onClick={() => setCreateItem(true)}>Adicionar</Button>}
        >
          {kpiQuery?.data?.length > 0 && type === 'affinitys' && (
            <SimpleGrid minChildWidth="400px" fontSize="11px" spacing={2} mb={10}>
              <Box bg="grayBlue.100" p={2} borderRadius="sm">
                <Box fontSize="18px" fontWeight="bold" color="grayBlue.500">
                  Riscos dos CNPJs
                </Box>
                <PieChart data={dataCNPJs} name="Total" />
              </Box>
              <Box bg="grayBlue.100" p={2} borderRadius="sm">
                <Box fontSize="18px" fontWeight="bold" color="grayBlue.500">
                  Situação das Empresas
                </Box>
                <PieChart data={dataSituations} name="Total" />
              </Box>
              {/* <Box bg='grayBlue.100'>deleted: <chakra.span fontSize='14px' fontWeight='bold'>{kpiQuery?.data[0]?.deleted}</chakra.span></Box>
              <Box bg='grayBlue.100'>enabled: <chakra.span fontSize='14px' fontWeight='bold'>{kpiQuery?.data[0]?.enabled}</chakra.span></Box>
              <Box bg='grayBlue.100'>group_id: <chakra.span fontSize='14px' fontWeight='bold'>{kpiQuery?.data[0]?.group_id}</chakra.span></Box> */}
              <Box bg="grayBlue.100" p={2} borderRadius="sm">
                <Box fontSize="18px" fontWeight="bold" color="grayBlue.500">
                  Status das Licenças
                </Box>
                <PieChart data={dataLicenses} name="Total" />
              </Box>
            </SimpleGrid>
          )}
          <Stack
            direction={{ base: 'column', md: 'row' }}
            mb={clientsQuery.data?.total == 0 && !search && !clientsQuery.isLoading ? '0' : '8'}
            justify="space-between"
            spacing="4"
          >
            <Stack direction={{ base: 'column', xl: 'row' }}>
              <ButtonSearch
                loading={clientsQuery.isFetching}
                valueSearch={search}
                total={clientsQuery.data?.total}
                handleSearch={handleSearch}
                handleClearSearch={handleClearSearch}
              />
            </Stack>

            <Stack direction={{ base: 'column', md: 'row' }} spacing="4" align="center">
              <ShowingItemsLegend total={clientsQuery.data?.total} limit={limit} page={page} />
              <Pagination
                page={page}
                handlePageChange={handlePageChange}
                pagesInRange={clientsQuery.data?.pages_in_range}
              />
            </Stack>
          </Stack>

          {clientsQuery.data?.items && clientsQuery.data?.total > 0 && !clientsQuery.isLoading ? (
            <>
              <SimpleGrid columns={[1, 1, 1, 2, 2, 3, 4, 4, 5, 6]} spacing="4">
                {clientsQuery.data?.items.map((client) => (
                  <VStack
                    key={client.id}
                    bgColor="grayBlue.100"
                    color="grayBlue.600"
                    fontSize="sm"
                    spacing="4"
                    p="4"
                    alignItems="left"
                    justify="space-between"
                    borderRadius="3px"
                    borderWidth="1px"
                    borderColor="grayBlue.100"
                    _hover={{ bgColor: 'grayBlue.200', textDecoration: 'none' }}
                  >
                    <HStack justify="space-between">
                      <Box>
                        <Heading
                          size="sm"
                          fontWeight="semibold"
                          color={client.enabled ? 'blue.400' : 'red.500'}
                          textTransform="capitalize"
                          onClick={() => handleSignInManager(client.id)}
                          _hover={{ cursor: 'pointer' }}
                        >
                          <span
                            style={
                              auth.email === 'apresentacao@econform.com.br'
                                ? {
                                  background: '-webkit-linear-gradient(0deg, #40B0F4, white, transparent)',
                                  '-webkit-background-clip': 'text',
                                  '-webkit-text-fill-color': 'transparent',
                                }
                                : {}
                            }
                          >
                            {client.name.toLowerCase()}
                          </span>

                          <chakra.span fontSize="3xs" color="grayBlue.500" ms="1">
                            ID{client.id}
                          </chakra.span>

                          {!client.enabled && <Badge colorScheme="red">Inativo</Badge>}
                          {daysDistance(new Date(client.createdAt?.date), 30) && (
                            <Badge colorScheme="purple">Novo</Badge>
                          )}
                        </Heading>
                        <chakra.span fontSize="sm" color="grayBlue.500">
                          <b>{client.totalCompanies || 0}</b> empresas
                        </chakra.span>
                        {!affinityCan && <Box fontSize="sm" color="grayBlue.500">
                          <Badge colorScheme="grayBlue" >{client.groupName}</Badge>
                        </Box>}
                      </Box>
                      <Box>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<Icon as={RiMore2Fill} w={6} h={6} />}
                            variant="unstyle"
                            minW="auto"
                            color="grayBlue.400"
                            _hover={{ color: 'blue.400' }}
                          />
                          <MenuList>
                            <MenuItem icon={<RiEdit2Fill />} onClick={() => setUpdateItem(Number(client.id))}>
                              Editar
                            </MenuItem>
                            <MenuItem
                              icon={<RiDeleteBin2Fill />}
                              onClick={() => setDeleteItem(Number(client.id))}
                              color="red.500"
                            >
                              Excluir
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Box>
                    </HStack>
                    <VStack spacing="2" align="stretch">
                      <Text fontSize="xs" color="grayBlue.500">
                        Criado em {date(client.createdAt?.date) || 'n/d'}
                        <br />
                        Editado em {date(client.updatedAt?.date) || 'n/d'}
                      </Text>
                    </VStack>
                  </VStack>
                ))}
              </SimpleGrid>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                mt="8"
                spacing="4"
                align="center"
                justify="space-between"
              >
                <ItemsPerPage
                  total={clientsQuery.data?.total}
                  limit={limit}
                  handlePerRowsChange={handlePerRowsChange}
                />
                <HStack direction={{ base: 'column', md: 'row' }} spacing="4" align="center" justify="end">
                  <ShowingItemsLegend total={clientsQuery.data?.total} limit={limit} page={page} />
                  <Pagination
                    page={page}
                    handlePageChange={handlePageChange}
                    pagesInRange={clientsQuery.data?.pages_in_range}
                  />
                </HStack>
              </Stack>
            </>
          ) : clientsQuery.data?.total == 0 && search && !clientsQuery.isLoading ? (
            <Result
              title={`Nada encontrado para "${search}"`}
              description="Escolha outro termo para buscar."
              icon={AiOutlineFileSearch}
              options={
                <Button type="button" variant="ghost" colorScheme="grayBlue" onClick={handleClearSearch}>
                  Voltar à lista
                </Button>
              }
            />
          ) : clientsQuery.data?.total == 0 && !clientsQuery.isLoading ? (
            <Result
              title="Vazio"
              description="Adicione algum item para montar a lista."
              icon={BsInboxFill}
              options={
                <Button type="button" onClick={() => setCreateItem(true)}>
                  Adicionar
                </Button>
              }
            />
          ) : null}
        </Paper>
      </Content>

      <Create
        isOpen={createItem}
        onClose={() => {
          setCreateItem(false);
          clientsQuery.refetch();
        }}
      />

      <Update
        id={updateItem}
        isOpen={!!updateItem}
        onClose={() => {
          setUpdateItem(0);
          clientsQuery.refetch();
        }}
      />

      <Delete
        id={deleteItem}
        isOpen={!!deleteItem}
        onClose={() => {
          setDeleteItem(0);
          clientsQuery.refetch();
        }}
      />
    </>
  );
};
